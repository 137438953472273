import Dropzone from 'dropzone';
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    thumbnails: Array,
  }

  connect() {
    this.dropZone = createDropZone(this);
    let dropZone = this.dropZone;

    let existingFiles = this.existingFilesValue;

    dropZone.on('maxfilesreached', function () {
      dropZone.removeEventListeners();
    });
    dropZone.on('removedfile', function (file) {
      dropZone.setupEventListeners();
    });

    // NOTE: Used to properly preview the thumbnails for files (not images)
    const updatePreview = () => {
      const previews = document.querySelectorAll('.dz-preview');
      previews.forEach((preview) => {
        const image = preview.querySelector('.dz-image img');
        const src = image.getAttribute('src');
        if (src === '[object Event]') {
          preview.classList.remove('dz-image-preview');
          preview.classList.add('dz-file-preview');
          image.removeAttribute('src');
          image.removeAttribute('alt');
        }
      });
    };

    // NOTE: It doesn't seem the commented code affects anything
    // TODO: needs to refactor (do not use hardcode input name and input id)
    // const inputName = 'device_archiving[files][]';
    // const hiddenInput = document.getElementById('device_archiving_files');

    existingFiles.forEach(function (file) {
      const mockFile = { name: file.name, size: file.size };
      dropZone.displayExistingFile(mockFile, file.url, updatePreview, 'anonymous');

      // const newInput = document.createElement('input');
      // newInput.type = 'hidden';
      // newInput.name = inputName;
      // newInput.value = file.signed_id;
      // insertAfter(newInput, hiddenInput);
    });

    let fileCountOnServer = existingFiles.length;
    dropZone.options.maxFiles = dropZone.options.maxFiles - fileCountOnServer;
    dropZone._updateMaxFilesReachedClass();

    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  addPlaceholder() {
    if (this.element.querySelectorAll('.dz-message').length > 0) return;

    const placeholderDiv = document.createElement('div');
    placeholderDiv.classList.add('dz-default', 'dz-message');
    const placeholderButton = document.createElement('button');
    placeholderButton.type = 'button';
    placeholderButton.classList.add('dz-button');
    placeholderButton.innerHTML = 'Upload file';
    placeholderDiv.append(placeholderButton);
    this.element.append(placeholderDiv);
  };

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  bindEvents() {
    this.dropZone.on('addedfile', file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on('removedfile', file => {
      // NOTE: aside form functionality: suggest survey assets and archive assets
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
        return;
      }

      this.dropZone.options.maxFiles = this.dropZone.options.maxFiles + 1;
      // NOTE: edit survey asset functionality: removes inputs that keep photos when validations fail
      // https://edgeguides.rubyonrails.org/active_storage_overview.html#form-validation
      removeElement(this.element.children[1]);
      this.addPlaceholder();
    });

    this.dropZone.on('canceled', file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }

  get existingFilesValue() {
    return JSON.parse(this.data.get('existingFilesValue') || '[]');
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', (event) => {
      this.uploadRequestDidProgress(event);
    });
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(this.file.previewTemplate, '.dz-upload').style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('thumbnail', this.file, this.source.thumbnailsValue[0]);
    if (this.file.type.match(/image.*/)) {
      this.source.dropZone.emit('thumbnail', this.file, this.source.thumbnailsValue[1]);
    }
    if (this.file.type.match(/application.pdf/)) {
      this.source.dropZone.emit('thumbnail', this.file, this.source.thumbnailsValue[2]);
    }
    if (this.file.type.match(/text.csv/) ||
        this.file.type.match(/application.vnd.ms-excel/) ||
        this.file.type.match(/application.vnd.openxmlformats-officedocument.spreadsheetml.sheet/)) {
      this.source.dropZone.emit('thumbnail', this.file, this.source.thumbnailsValue[3]);
    }
    if (this.file.type.match(/text.plain/)) {
      this.source.dropZone.emit('thumbnail', this.file, this.source.thumbnailsValue[4]);
    }
    this.source.dropZone.emit('complete', this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    dictDefaultMessage: 'Upload file',
    thumbnailWidth: 24,
    thumbnailHeight: 24,
    autoQueue: false
  });
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
}

function findElement(root, selector) {
  if (typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}
