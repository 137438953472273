/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'roleEditOption',
    'roleIdField',
    'stateEditOption',
    'stateEventField',
    'jobTitleEditOption',
    'jobTitleField',
    'managerEmailEditOption',
    'managerEmailField',
    'locationTypeEditOption',
    'locationTypeField',
    'contractTypeEditOption',
    'contractTypeField',
    'departmentIdsEditOption',
    'departmentIdsField',
    'locationIdsEditOption',
    'locationIdsField',
    'legalEntityIdsEditOption',
    'legalEntityIdsField',
  ];

  _showFields(fields) {
    fields.forEach((field) => field.classList.remove('d-none'));
  }

  _hideFields(fields) {
    fields.forEach((field) => field.classList.add('d-none'));
  }

  _toggleRoleIdField() {
    const select = this.roleEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.roleIdFieldTargets);
    } else {
      this._hideFields(this.roleIdFieldTargets);
    }
  }

  _toggleStateEventField() {
    const select = this.stateEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.stateEventFieldTargets);
    } else {
      this._hideFields(this.stateEventFieldTargets);
    }
  }

  _toggleJobTitleField() {
    const select = this.jobTitleEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.jobTitleFieldTargets);
    } else {
      this._hideFields(this.jobTitleFieldTargets);
    }
  }

  _toggleManagerEmailField() {
    const select = this.managerEmailEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.managerEmailFieldTargets);
    } else {
      this._hideFields(this.managerEmailFieldTargets);
    }
  }

  _toggleLocationTypeField() {
    const select = this.locationTypeEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.locationTypeFieldTargets);
    } else {
      this._hideFields(this.locationTypeFieldTargets);
    }
  }

  _toggleContractTypeField() {
    const select = this.contractTypeEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.contractTypeFieldTargets);
    } else {
      this._hideFields(this.contractTypeFieldTargets);
    }
  }

  _toggleDepartmentIdsField() {
    const select = this.departmentIdsEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.departmentIdsFieldTargets);
    } else {
      this._hideFields(this.departmentIdsFieldTargets);
    }
  }

  _toggleLocationIdsField() {
    const select = this.locationIdsEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.locationIdsFieldTargets);
    } else {
      this._hideFields(this.locationIdsFieldTargets);
    }
  }

  _toggleLegalEntityIdsField() {
    const select = this.legalEntityIdsEditOptionTarget;
    const selectedOption = select.options[select.selectedIndex].value;
    if (selectedOption == 'replace') {
      this._showFields(this.legalEntityIdsFieldTargets);
    } else {
      this._hideFields(this.legalEntityIdsFieldTargets);
    }
  }

  updateForm() {
    if (this.hasRoleIdFieldTarget) this._toggleRoleIdField();
    if (this.hasStateEventFieldTarget) this._toggleStateEventField();
    if (this.hasJobTitleFieldTarget) this._toggleJobTitleField();
    if (this.hasManagerEmailFieldTarget) this._toggleManagerEmailField();
    if (this.hasLocationTypeFieldTarget) this._toggleLocationTypeField();
    if (this.hasContractTypeFieldTarget) this._toggleContractTypeField();
    if (this.hasDepartmentIdsFieldTarget) this._toggleDepartmentIdsField();
    if (this.hasLocationIdsFieldTarget) this._toggleLocationIdsField();
    if (this.hasLegalEntityIdsFieldTarget) this._toggleLegalEntityIdsField();
  }

  connect() {
    this.updateForm();
  }
}
