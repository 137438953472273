/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = [
    'checkbox',
    'bulkActionControl',
    'actionControl',
    'bulkActionEditButton',
    'bulkActionInviteButton',
    'bulkActionConvertToServiceAccountButton',
    'bulkActionArchiveButton',
    'bulkActionUnassignButton',
    'selectAllUsersPanel',
    'selectAllSelectedUsersCountText',
    'editBtn',
  ];

  static values = {
    selectedUsersCount: Number,
    allUsersCount: Number,
    tickPath: String,
    untickPath: String,
    unrestricted: Boolean,
  };

  async _submit_user_ids(user_ids, path) {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ user_ids }),
      });

      if (!response.ok) {
        alert('Something went wrong. Please contact support.');
        return;
      }

      const body = await response.json();
      this.selectedUsersCountValue = body.user_ids_count;
      this._toggleControls();
    } catch (error) {
      alert('Something went wrong. Please contact support.');
    }
  }

  _showSelectAllUsersPanel() {
    this.selectAllUsersPanelTarget.classList.remove('d-none');
  }

  _hideSelectAllUsersPanel() {
    this.selectAllUsersPanelTarget.classList.add('d-none');
  }

  _hideEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.add('d-none'));
  }

  _showEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.remove('d-none'));
  }

  bulkCheckboxChanged(event) {
    const state = event.target.checked;
    const user_ids = [];
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
      user_ids.push(checkbox.value);
    });
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_user_ids(user_ids, path);
    this._toggleControls();
    if (state == true) {
      this._showSelectAllUsersPanel();
      this._hideEditButtons();
    } else {
      this._hideSelectAllUsersPanel();
      this._showEditButtons();
    }
  }

  checkboxChanged(event) {
    const state = event.target.checked;
    const user_id = event.target.value;
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_user_ids([user_id], path);
    this._hideSelectAllUsersPanel();
  }

  _updateBulkControls() {
    const selectedCount = this.selectedUsersCountValue;
    this.selectAllSelectedUsersCountTextTarget.innerHTML = i18n.t('web.users.shared.bulk_action_progress_panel.select_all_selected_html', { selected_users_count: selectedCount });
    this.bulkActionEditButtonTarget.innerHTML = i18n.t('web.users.shared.bulk_action_buttons.bulk_edit', { count: selectedCount });
    if (this.hasBulkActionInviteButtonTarget) this.bulkActionInviteButtonTarget.innerHTML = i18n.t('web.users.shared.bulk_action_buttons.bulk_invite', { count: selectedCount });
    if (this.hasBulkActionConvertToServiceAccountButtonTarget) this.bulkActionConvertToServiceAccountButtonTarget.innerHTML = i18n.t('web.users.shared.bulk_action_buttons.bulk_convert_to_service_account', { count: selectedCount });
    if (this.hasBulkActionArchiveButtonTarget) this.bulkActionArchiveButtonTarget.innerHTML = i18n.t('web.users.shared.bulk_action_buttons.bulk_archive', { count: selectedCount });
    if (this.hasBulkActionUnassignButtonTarget) this.bulkActionUnassignButtonTarget.innerHTML = i18n.t('web.users.shared.bulk_action_buttons.bulk_unassign', { count: selectedCount });
  }

  _toggleCardControls() {
    const hideCardControls = this.selectedUsersCountValue > 0;
    if (hideCardControls) {
      this.actionControlTargets.forEach((control) => {
        control.classList.add(control.dataset.hideClass);
      });
      this._hideEditButtons();
      return;
    }

    this.actionControlTargets.forEach((control) => {
      control.classList.remove(control.dataset.hideClass);
      this._showEditButtons();
    });
  }

  _toggleBulkControls() {
    if (this.selectedUsersCountValue > 0) {
      this.bulkActionControlTargets.forEach((control) => {
        control.classList.remove(control.dataset.hideClass);
      });
      return;
    }

    this.bulkActionControlTargets.forEach((control) => {
      control.classList.add(control.dataset.hideClass);
    });
  }

  _toggleControls() {
    if (!this.unrestrictedValue) return;

    this._updateBulkControls();
    this._toggleBulkControls();
    this._toggleCardControls();
  }

  connect() {
    this._toggleControls();
  }
}
