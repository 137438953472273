import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'deviceSearch',
    'deviceType',
    'location',
    'deviceList',
    'spinner',
  ];

  deviceSearch() {
    const spinner = this.spinnerTarget;
    spinner.classList.remove('d-none');
    this.deviceListTarget.innerHTML = '';
    const query = {
      'q[filter_by_fragment]': this.deviceSearchTarget.value,
      'q[device_type_name_eq]': this.deviceTypeTarget.value,
      'q[locations_id_in]': this.locationTarget.value,
    };
    const url = `/reminders/physical_assets.js?${new URLSearchParams(query)}`;

    Rails.ajax({
      url,
      type: 'GET',
      success(data) {
        spinner.classList.add('d-none');
      },
      error(data) {
        alert(data.responseText);
      },
    });
  }

  connect() {
    this.deviceSearch();
  }
}
