/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    showOnConnect: Boolean,
  };

  static targets = ['autofocus'];

  connect() {
    // NOTE: Prevents re-fetching the content based on the src attribute
    // For details see: https://labs.k.io/lessons-learned-with-hotwire/
    this.offcanvasTurboFrame.removeAttribute('src');

    if (!this.showOnConnectValue) return;

    const offcanvas = new bootstrap.Offcanvas(this.element);
    offcanvas.show();
  }

  get offcanvasTurboFrame() {
    return document.querySelector("turbo-frame[id='turbo-offcanvas']");
  }

  _hideOffcanvas() {
    this.offcanvasTurboFrame.removeAttribute('src');

    const offcanvas = bootstrap.Offcanvas.getInstance(this.element);
    offcanvas.hide();
    this.element.remove();
  }

  submitEnd(e) {
    const skipHandle = e.target.dataset.offcanvasSubmit === 'false';
    if (e.detail.success && !skipHandle) this._hideOffcanvas();
  }
}
