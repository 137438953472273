/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['yesBtn', 'noBtn', 'skipBtn'];
  static values =  {
    state: String,
    value: Boolean
  };

  connect() {
    // const target = { target: this.radioConfirmationTarget };
    // this.toggleInput(target);
  }
}
